import { Box, Link, Typography } from '@mui/material';
import { FC } from 'react';

import FooterWrapper, {
  FooterInnerWrapper,
} from '@/components/common/Footer/styles/FooterWrapper';
import {
  OSTANA_MAIN_SITE,
  PRIVACY_POLICY_URL,
  TERMS_AND_CONDITION_URL,
} from '@/utils/constants';

type LinkType = {
  title: string;
  href: string;
};

const links: LinkType[] = [
  {
    title: 'Privacy Policy',
    href: PRIVACY_POLICY_URL,
  },
  {
    title: 'Terms of Use',
    href: TERMS_AND_CONDITION_URL,
  },
];

interface Props {
  authScreen?: boolean;
}

const FooterLink: FC<{ link: LinkType }> = ({ link }) => (
  <Link
    href={link.href}
    target="_blank"
    rel="noreferrer"
    sx={(theme) => ({
      color: theme.palette.text.caption,
      ':hover': {
        color: theme.palette.primary.main,
      },
    })}
    variant="p1"
  >
    {link.title}
  </Link>
);

export const Footer: FC<Props> = ({ authScreen }) => {
  return (
    <FooterWrapper component="footer">
      <FooterInnerWrapper>
        <Box
          sx={(theme) => ({
            display: 'flex',
            columnGap: 4,
            rowGap: 2.5,
            flexDirection: authScreen ? 'column' : 'row',
            alignItems: 'center',
            [theme.breakpoints.up('tablet')]: {
              flexDirection: 'row',
            },
          })}
        >
          {authScreen && (
            <FooterLink
              link={{
                title: 'Visit Ostana.io',
                href: OSTANA_MAIN_SITE,
              }}
            />
          )}
          <Box sx={{ display: 'flex', columnGap: '32px' }}>
            {links.map((x) => (
              <FooterLink link={x} key={`${x.href}-${x.title}`} />
            ))}
          </Box>
        </Box>
        <Box>
          <Typography variant="p1" color="text.caption">
            © 2024 Ostana. All rights reserved.
          </Typography>
        </Box>
      </FooterInnerWrapper>
    </FooterWrapper>
  );
};
