import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const FooterWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  background: theme.palette.background.cardExtraLayer,
  width: '100%',
}));

export const FooterInnerWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(3, 2),
  gridColumn: '1 / 3',
  width: '100%',
  flexDirection: 'column',
  rowGap: theme.spacing(2.5),
  [theme.breakpoints.up('tablet')]: {
    padding: theme.spacing(3, 4),
    flexDirection: 'row',
  },
  [theme.breakpoints.up('desktopL')]: {
    padding: theme.spacing(3, 5),
  },
  [theme.breakpoints.up('desktopXL')]: {
    width: '1920px',
  },
}));

export default FooterWrapper;
