import { Grid, Typography } from '@mui/material';
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';

import LoginContainer, { LoginWrapper } from './styles/LoginContainer';
import CommonButton from '@/components/common/CommonButton';
import { Footer } from '@/components/common/Footer';
import Logo from '@/components/icons/Logo';
import LoginItemWrapper, {
  LoginInnerItemWrapper,
} from '@/components/pages/Login/styles/LoginItemWrapper';
import LogoWrapper from '@/components/pages/Login/styles/LogoWrapper';
import { getSavedLogin } from '@/utils/functions/loginUtils';
import { useObservableLocalStorage } from '@/utils/hooks/useLocalStorage';
import { routePaths } from '@/utils/routePaths';
import { USER_TYPE } from '@/utils/types';

export const INNER_LOGIN_NAVIGATION = 'inner-login-navigation';

const Login = () => {
  const router = useRouter();
  const [, setIsSent] = useObservableLocalStorage('downgrade-message-sent');

  useEffect(() => {
    const login = getSavedLogin();
    if (!login) return;
    if (login === USER_TYPE.OWNER) {
      localStorage.setItem(INNER_LOGIN_NAVIGATION, 'true');
      router.push(routePaths.owner.login);
    } else if (login === USER_TYPE.CUSTOMER) {
      localStorage.setItem(INNER_LOGIN_NAVIGATION, 'true');
      router.push(routePaths.customer.login);
    }
  }, [router]);

  return (
    <LoginWrapper>
      <LoginContainer>
        <Grid
          sx={{ width: '100%', maxWidth: '1130px' }}
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
          <Typography
            variant="h1"
            sx={(theme) => ({
              mt: 4,
              mb: 1,
              width: '180px',
              textAlign: 'center',
              [theme.breakpoints.up('tablet')]: {
                mt: 7.5,
                mb: 1.5,
                width: 'auto',
              },
            })}
          >
            Welcome to Ostana!
          </Typography>
          <Typography
            variant="p1"
            sx={(theme) => ({
              mb: 4,
              [theme.breakpoints.up('tablet')]: {
                mb: 5,
              },
            })}
          >
            How would you like to get started?
          </Typography>
          <Grid
            display="flex"
            justifyContent="center"
            gap={2.5}
            container
            px={2}
          >
            <LoginItemWrapper
              onClick={() => {
                localStorage.setItem(INNER_LOGIN_NAVIGATION, 'true');
                router.push(routePaths.owner.login);
                setIsSent(false);
              }}
              sx={(theme) => ({
                backgroundImage: 'url("/images/businessSignBg.jpeg")',
                backgroundColor: 'lightgray',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '-22.08px -58.402px',
                backgroundSize: '132.116% 168.041%',
                [theme.breakpoints.up('tablet')]: {
                  backgroundPosition: '-11.025px -41.596px',
                  backgroundSize: '138.815% 82.892%',
                },
              })}
            >
              <LoginInnerItemWrapper>
                <Typography
                  variant="h4Bold"
                  color="white.main"
                  sx={{ textAlign: 'center' }}
                >
                  Business
                </Typography>
                <Typography
                  variant="p2"
                  color="system.inputBorder"
                  sx={(theme) => ({
                    [theme.breakpoints.up('tablet')]: { whiteSpace: 'nowrap' },
                  })}
                >
                  Loyalty solution built for small businesses
                </Typography>
              </LoginInnerItemWrapper>
              <CommonButton
                data-testid="business-sign-in"
                sx={(theme) => ({
                  width: '180px',
                  zIndex: 10,
                  mb: 1.5,
                  [theme.breakpoints.up('tablet')]: { mb: 0 },
                })}
              >
                Sign In
              </CommonButton>
            </LoginItemWrapper>
            <LoginItemWrapper
              onClick={() => {
                localStorage.setItem(INNER_LOGIN_NAVIGATION, 'true');
                router.push(routePaths.customer.login);
                setIsSent(false);
              }}
              sx={(theme) => ({
                backgroundImage: 'url("/images/customerSignBg.jpg")',
                backgroundPosition: '-107.366px -44.887px',
                backgroundSize: '145.012% 184.444%',
                backgroundColor: 'lightgray',
                backgroundRepeat: 'no-repeat',
                [theme.breakpoints.up('tablet')]: {
                  backgroundPosition: '-72.517px -4.343px',
                  backgroundSize: '138.815% 80.385%',
                },
              })}
            >
              <LoginInnerItemWrapper>
                <Typography
                  variant="h4Bold"
                  color="white.main"
                  sx={{ textAlign: 'center', mb: 0.5 }}
                >
                  Customer
                </Typography>
                <Typography variant="p2" color="system.inputBorder">
                  Personalized rewards for all shoppers
                </Typography>
              </LoginInnerItemWrapper>
              <CommonButton
                sx={(theme) => ({
                  width: '180px',
                  zIndex: 10,
                  mb: 1.5,
                  [theme.breakpoints.up('tablet')]: { mb: 0 },
                })}
              >
                Sign In
              </CommonButton>
            </LoginItemWrapper>
          </Grid>
        </Grid>
      </LoginContainer>
      <Footer authScreen />
    </LoginWrapper>
  );
};

export default Login;
