import { styled } from '@mui/material/styles';

const LogoWrapper = styled('div')(({ theme }) => ({
  '& svg': {
    width: '138px',
    height: '40px',
  },
  [theme.breakpoints.up('tablet')]: {
    '& svg': {
      width: '208px',
      height: '60px',
    },
  },
}));

export default LogoWrapper;
